import { getWixSDK } from './wix-sdk';
import parseQueryParams from './get-query-params';

const getRootCompId = () => {
  const { rootCompId } = parseQueryParams(window.location.search);
  return rootCompId;
};

export const getCompId = () =>
  getRootCompId() ||
  getWixSDK().Utils.getOrigCompId() ||
  getWixSDK().Utils.getCompId();
