import React, { FC, useContext } from 'react';
import { ControllerState } from '../worker/controller-handlers';
import { Handlers } from '../worker/controller-handlers/handlers';

export type ControllerHandlersWithState = {
  controllerState?: ControllerState;
  handlers?: Handlers;
};

export const ControllerStateContext =
  React.createContext<ControllerHandlersWithState>({});

export const withHandlersAndState = (
  Component: FC<ControllerHandlersWithState>,
) => {
  return ((props) => {
    const { controllerState, handlers } = useContext(ControllerStateContext);

    return (
      <Component
        {...props}
        controllerState={controllerState}
        handlers={handlers}
      />
    );
  }) as FC;
};
