import { createSettingsParams, SettingsParamType } from '@wix/tpa-settings';

export type ISettingsParams = {
  channelId: SettingsParamType.String;
  videoId: SettingsParamType.String;
  showTitleForMainItem: SettingsParamType.Boolean;
  useIdsFromSettings: SettingsParamType.Boolean;
};

export const settingsParams = createSettingsParams<ISettingsParams>({
  channelId: {
    type: SettingsParamType.String,
    getDefaultValue: () => 'a1ca9dac7ee44d52a41868329471105b',
  },
  videoId: {
    type: SettingsParamType.String,
    getDefaultValue: () => '',
  },
  showTitleForMainItem: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => false,
  },
  useIdsFromSettings: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => false,
  },
});
export default settingsParams;
