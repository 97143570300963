import React from 'react';
import _ from 'lodash';

import classnames from 'classnames';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { visitChannel } from '../../../../../redux/actions/mobile-overlay-openners/main-openners';
import { logBi } from '../../../../../worker/actions/bi';
import { openSubscribeOverlay } from '../../../../../redux/actions/mobile-overlay-openners/payment-pages-actions';
import SlideMenuButton from '../buttons/slide-menu-button';
import { logOutCurrentMember, requestLogin } from '../../../../../utils/auth';
import { notForPreview } from '../../../../../utils/not-for-preview';
import {
  getVisibleMenuItems,
  ALL_MENU_ITEMS,
} from '../../ui-selectors/slide-menu';
import { subscribeButtonText } from '../../ui-selectors/buttons';
import ShareMobile from '../../../../components/share-mobile/share-mobile';
import { titleCase } from '../../../../utils/capitalize';
import { closeSlideMenu } from '../../../../redux/actions/slide-menu';

import styles from './slide-menu.scss';
import { withTranslation, withFedopsLogger } from '@wix/yoshi-flow-editor';

const mapStateToProps = (state, props) => ({
  subscribeButtonText: subscribeButtonText(state, props),
  visibleMenuItems: getVisibleMenuItems(state, props),
});

const mapDispatchToProps = {
  closeSlideMenu,
  visitChannel,
  openSubscribeOverlay,
  logBi,
  logOutCurrentMember,
  requestLogin,
};

export default withFedopsLogger(
  withTranslation()(
    connect(
      mapStateToProps,
      mapDispatchToProps,
    )(
      class SlideMenu extends React.Component {
        static propTypes = {
          videoItem: PropTypes.object,
          channelData: PropTypes.object.isRequired,
          subscribeButtonText: PropTypes.object,

          isFirstVideo: PropTypes.bool,
          showMenuButton: PropTypes.bool,
          stretchToHeight: PropTypes.bool,

          visibleMenuItems: PropTypes.array,

          onMemberSignUp: PropTypes.func,
          closeSlideMenu: PropTypes.func,
          visitChannel: PropTypes.func,
          logOutCurrentMember: PropTypes.func.isRequired,
          requestLogin: PropTypes.func.isRequired,
          openSubscribeOverlay: PropTypes.func,

          menuClassName: PropTypes.string,
        };

        static defaultProps = {
          showMenuButton: true,
        };

        constructor(props) {
          super(props);

          this.menuItemsRenderMap = {
            [ALL_MENU_ITEMS.VISIT_CHANNEL]: this.renderVisitChannel,
            [ALL_MENU_ITEMS.SUBSCRIBE]: this.renderSubscribe,
            [ALL_MENU_ITEMS.SIGN_OUT]: this.renderSignOut,
            [ALL_MENU_ITEMS.SIGN_IN]: this.renderSignIn,
            [ALL_MENU_ITEMS.SHARE_CHANNEL]: this.renderChannelShare,
            [ALL_MENU_ITEMS.SHARE_VIDEO]: this.renderVideoShare,
          };
        }

        renderMenuItems = () => {
          const { visibleMenuItems } = this.props;
          const nullFn = () => null;

          return _.map(visibleMenuItems, (item) =>
            _.get(this.menuItemsRenderMap, item, nullFn)(),
          );
        };

        renderItem = (item) => {
          const text = item.text || titleCase(this.props.t(item.title));

          return (
            <div
              data-hook={item.dataHook}
              key={item.dataHook}
              className={styles['menu-item']}
              onClick={item.onClick}
              role="button"
              aria-label={text}
            >
              {text}
            </div>
          );
        };

        visitChannel = () => {
          this.props.visitChannel();
        };

        renderVisitChannel = () => {
          return this.renderItem({
            dataHook: 'visit-channel-menu-item',
            title: 'widget.mobile.overlay-button.visit-channel',
            onClick: this.visitChannel,
          });
        };

        renderSubscribe = () => {
          const { subscribeButtonText } = this.props;
          return this.renderItem({
            dataHook: 'subscribe-menu-item',
            text: subscribeButtonText,
            onClick: this.props.openSubscribeOverlay,
          });
        };

        renderSignOut = () => {
          return this.renderItem({
            dataHook: 'sign-out-menu-item',
            title: 'widget.mobile.overlay-button.sign-out',
            onClick: this.props.logOutCurrentMember,
          });
        };

        renderSignIn = () => {
          this.props.logBi('widget.signIn.clicked');

          return this.renderItem({
            dataHook: 'sign-in-menu-item',
            title: 'widget.mobile.overlay-button.sign-in',
            onClick: notForPreview(() => this.props.requestLogin()),
          });
        };

        renderChannelShare = () => {
          const { channelData } = this.props;

          return (
            <ShareMobile
              channelData={channelData}
              key="share-channel-menu-item"
              dataHook="share-channel-menu-item"
            >
              {this.renderItem({
                dataHook: 'share-menu-item',
                title: 'share-overlay.social.title',
              })}
            </ShareMobile>
          );
        };

        renderVideoShare = () => {
          const { channelData, videoItem } = this.props;

          return (
            <ShareMobile
              videoItem={videoItem}
              channelData={channelData}
              key="share-video-menu-item"
              dataHook="share-video-menu-item"
            >
              {this.renderItem({
                dataHook: 'share-menu-item',
                title: 'share-overlay.social.title',
              })}
            </ShareMobile>
          );
        };

        render() {
          const {
            closeSlideMenu,
            showMenuButton,
            stretchToHeight,
            menuClassName,
          } = this.props;

          return (
            <div className={styles['menu-wrapper']}>
              <div
                data-hook="menu-close-area"
                className={styles['menu-close-area']}
                onClick={closeSlideMenu}
              />
              <div
                data-hook="slide-menu"
                className={classnames(
                  styles.menu,
                  menuClassName,
                  styles['with-mobile-colors'],
                  {
                    [styles['stretch-to-height']]: stretchToHeight,
                  },
                )}
              >
                {showMenuButton && (
                  <SlideMenuButton
                    onClick={closeSlideMenu}
                    className={styles['menu-icon-wrapper']}
                    iconClassName={styles['menu-icon']}
                    alwaysShow
                  />
                )}
                <div className={styles['menu-items']}>
                  {this.renderMenuItems()}
                </div>
              </div>
            </div>
          );
        }
      },
    ),
  ),
);
