import { useEnvironment } from '@wix/yoshi-flow-editor';
import { usePreviewPrompt } from '../common/providers/PreviewProvider';

type Func<T extends unknown[]> = <T extends unknown[]>(...args: T) => void;

export const useNotForPreview = () => {
  const { isPreview, isEditor } = useEnvironment();
  const { open: openPreviewPrompt } = usePreviewPrompt();

  return <T extends unknown[]>(fn: Func<T>): Func<T> => {
    if (isPreview || isEditor) {
      return () => {
        openPreviewPrompt();
      };
    }
    return fn;
  };
};
