import React, { useContext } from 'react';
import { GqlVideoFragment } from '../apollo/generated/graphql';

type ChannelVideosContextType = {
  videos: GqlVideoFragment[];
  loadMoreVideos(): Promise<void>;
  videosSearching: boolean;
};

export const ChannelVideosContext =
  React.createContext<ChannelVideosContextType>({
    videos: [],
    loadMoreVideos: async () => {},
    videosSearching: false,
  });

export function useChannelVideos(): ChannelVideosContextType {
  return useContext(ChannelVideosContext);
}
