export function dashify(val?: string) {
  return val && val.length === 32
    ? val.replace(
        /([0-9a-f]{8})([0-9a-f]{4})([0-9a-f]{4})([0-9a-f]{4})([0-9a-f]{12})/,
        '$1-$2-$3-$4-$5',
      )
    : val;
}

export function removeDashes(id?: string) {
  return id ? id.replace(/-/g, '') : id;
}
