import _ from 'lodash';
import { keysToCamelCase } from '@wix/wix-vod-shared/common';

function TransportError(status, url, error) {
  this.status = status;
  this.error = error;
  this.url = url;
  this.name = this.constructor.name;
}

TransportError.prototype = _.create(Error.prototype);

function getUrl(response) {
  const urlFromWixMP = _.get(response, 'config.url');
  return urlFromWixMP || response.url;
}

export function toError(response = {}) {
  response = response.response || response;

  if (response instanceof TransportError) {
    return Promise.reject(response);
  }

  const { code, status, data = {}, message } = response;
  const url = getUrl(response);

  const dataWithCamelCase = keysToCamelCase(data);
  const { errorCode, errorDescription = message } = dataWithCamelCase;

  return Promise.reject(
    new TransportError(status || code, url, {
      code: errorCode,
      data: dataWithCamelCase,
      message: errorDescription,
    }),
  );
}
