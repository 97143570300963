import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useState,
} from 'react';

type PreviewContextType = {
  isOpen: boolean;
  open: () => void;
  close: () => void;
};

const PreviewContext = createContext<PreviewContextType | null>(null);

export const usePreviewPrompt = (): PreviewContextType => {
  const previewContext = useContext(PreviewContext);
  if (!previewContext) {
    throw Error(
      'This hook can only be called inside <PreviewProvider> component',
    );
  }
  return previewContext;
};

export const PreviewProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const open = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);
  const close = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);
  return (
    <PreviewContext.Provider value={{ isOpen, open, close }}>
      {children}
    </PreviewContext.Provider>
  );
};
