import React from 'react';
import LayoutSwitcher from './layouts/layout-switcher';
import { Direction } from './containers/direction';
import { widgetWrapper } from './worker/widgetWrapper';
import { createStore } from './widget.store';
import { useReloadOnIdsChange } from './utils/useReloadOnIdsChange';
import { WidgetWidthProvider } from './containers/widget-width';
import { useLoadMadeFor } from './utils/useLoadMadeFor';
import { WidgetResizer } from './components/widget-resizer/widget-resizer';
import { WindowSize } from './containers/window-size';

function Component() {
  useReloadOnIdsChange();
  useLoadMadeFor();

  return (
    <WindowSize>
      <WidgetResizer>
        <WidgetWidthProvider>
          <Direction>
            <LayoutSwitcher />
          </Direction>
        </WidgetWidthProvider>
      </WidgetResizer>
    </WindowSize>
  );
}

export const DesktopWidgetComponent = widgetWrapper({
  createStore,
  Component,
});
