export enum DesktopLayout {
  Classic = 1,
  Strip = 2,
  Compact = 3,
  BigSlider = 4,
  SmallSlider = 5,
  Grid = 6,
}

export enum MobileLayout {
  Compact = 3,
  Vertical = 6,
}
