import {
  GqlVideoFragment,
  GqlVideoPricingType,
} from '../apollo/generated/graphql';

export const useVideoPricing = (video?: GqlVideoFragment) => {
  let rentEnabled = false,
    saleEnabled = false,
    rentPrice = 0,
    salePrice = 0,
    minPrice = 0,
    currency = '',
    isFreeVideo = false;

  if (video) {
    const { pricing } = video;

    for (const p of pricing) {
      const { enabled, type, price } = p;

      if (enabled) {
        if (type === GqlVideoPricingType.Rent) {
          rentEnabled = true;
          rentPrice = price;
        }

        if (type === GqlVideoPricingType.Sale) {
          saleEnabled = true;
          salePrice = price;
        }

        currency = currency || p.currency;
        minPrice = minPrice === 0 ? price : Math.min(minPrice, price);
      }
    }

    isFreeVideo = !rentEnabled && !saleEnabled;
  }

  return {
    rentEnabled,
    saleEnabled,
    rentPrice,
    salePrice,
    minPrice,
    currency,
    isFreeVideo,
  };
};
