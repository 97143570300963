import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { requestPause } from '../../../redux/reducers/playback';
import { setHydratedData } from '../../../redux/hydrated-data/hydrated-data';
import { ViewModeObserver } from '../../../containers/view-mode-observer';
import * as viewModeSelectors from '../../../selectors/view-mode';
import { VIEW_MODES } from '@wix/wix-vod-constants/common';

const mapStateToProps = (state) => ({
  viewMode: viewModeSelectors.getViewMode(state),
  selectedVideoId: state.selectedVideoId,
});

const mapDispatchToProps = {
  requestPause,
  setHydratedData,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  class EditorObserver extends React.Component {
    static propTypes = {
      setHydratedData: PropTypes.func.isRequired,
      requestPause: PropTypes.func.isRequired,
      viewMode: PropTypes.string.isRequired,
      selectedVideoId: PropTypes.string,
    };

    handleViewModeChange = ({ prevViewMode, viewMode }) => {
      const { requestPause, selectedVideoId } = this.props;

      if (prevViewMode === VIEW_MODES.PREVIEW && viewMode !== prevViewMode) {
        requestPause(selectedVideoId);
      }
    };

    render() {
      return <ViewModeObserver onChange={this.handleViewModeChange} />;
    }
  },
);
