import { useMemo } from 'react';
import {
  GqlLiveStatus,
  GqlVideoFragment,
  GqlVideoSource,
} from '../apollo/generated/graphql';

export const useVideoSource = (video: GqlVideoFragment) => {
  const { source, live } = video;

  const isExternalVideo = useMemo(() => {
    const { Vimeo, Youtube, Facebook } = GqlVideoSource;
    return [Vimeo, Youtube, Facebook].includes(source);
  }, [source]);

  const isScheduledLive = useMemo(
    () =>
      source === GqlVideoSource.Live &&
      live?.status === GqlLiveStatus.Scheduled,
    [source, live],
  );

  const isStreamingLive = useMemo(
    () =>
      source === GqlVideoSource.Live &&
      live?.status === GqlLiveStatus.Streaming,
    [source, live],
  );

  return { isExternalVideo, isScheduledLive, isStreamingLive };
};
