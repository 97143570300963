import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import Slider from '../slider/slider';
import styles from './video-list.scss';
import VideoThumbnail from '../video-thumbnail/video-thumbnail';
import ActionCallbacks from '../../containers/action-callbacks/action-callbacks';
import { loadMore } from '../../redux/lazy-channel-videos/actions';
import {
  getIsFetching,
  getCursor,
} from '../../redux/lazy-channel-videos/selectors';
import { ClampText, ALIGNMENT } from '@wix/wix-vod-shared/components';
import { getThumbnailSpacing, isRTL } from '../../selectors/app-settings';
import { getCategory } from '../../selectors/search';
import { withQueryWidgetWidth } from '../../containers/widget-width';

const BREAKPOINTS = [
  {
    max: 639,
    width: 560,
    height: 315,
  },
  {
    max: 1279,
    width: 592,
    height: 333,
  },
  {
    min: 1280,
    width: 608,
    height: 342,
  },
];

const mapStateToProps = (state) => ({
  thumbnailSpacing: getThumbnailSpacing(state),
  nextVideosCursor: getCursor(state),
  isFetching: getIsFetching(state),
  category: getCategory(state),
  isRTL: isRTL(state),
});

const mapDispatchToProps = {
  loadMore,
};

class VideoList extends React.Component {
  static propTypes = {
    thumbnailSpacing: PropTypes.number.isRequired,
    channel: PropTypes.object.isRequired,
    videoIds: PropTypes.array.isRequired,
    videoByIds: PropTypes.object.isRequired,
    loadMore: PropTypes.func.isRequired,
    queryWidget: PropTypes.func.isRequired,
    onPlayRequestedBi: PropTypes.func,
    isFetching: PropTypes.bool.isRequired,
    nextVideosCursor: PropTypes.string,
    itemsCount: PropTypes.number,
    category: PropTypes.string,
  };

  loadMore = (itemsToLoadCount) => {
    const { nextVideosCursor, isFetching, loadMore, category } = this.props;

    if (nextVideosCursor && !isFetching) {
      loadMore(itemsToLoadCount, { category });
    }
  };

  renderThumbnailContent = ({
    title,
    description,
    publisher,
    alignment,
    onClick,
  }) => {
    const contentClassNames = this.props.queryWidgetWidth([
      { minWidth: 1280, className: styles.contentMin1280 },
    ]);

    const classes = classNames(
      styles.content,
      {
        [styles['align-left']]: alignment === ALIGNMENT.LEFT,
        [styles['align-center']]: alignment === ALIGNMENT.CENTER,
        [styles['align-right']]: alignment === ALIGNMENT.RIGHT,
      },
      contentClassNames,
    );

    return (
      <div className={classes} onClick={onClick}>
        <div className={styles.publisher}>{publisher}</div>
        <ClampText text={title} className={styles.title} />
        <ClampText text={description} className={styles.description} />
      </div>
    );
  };

  renderItem = (videoId) => {
    const {
      videoByIds,
      channel,
      currentSiteUser,
      onPlayRequestedBi,
      thumbnailSpacing,
      isRTL,
    } = this.props;

    const isLoading = !videoId;
    const video = isLoading ? {} : videoByIds[videoId];
    const padding = isRTL ? 'padding-left' : 'padding-right';

    return (
      <div style={{ [padding]: thumbnailSpacing }}>
        <ActionCallbacks
          channelId={channel.id}
          videoItem={video}
          onPlayRequestedBi={onPlayRequestedBi}
          key={videoId}
          isResponsive
        >
          <VideoThumbnail
            isLoading={isLoading}
            videoItem={video}
            key={videoId}
            channelData={channel}
            isContentFocusable
            currentSiteUser={currentSiteUser}
            breakpoints={BREAKPOINTS}
            renderThumbnailContent={this.renderThumbnailContent}
          />
        </ActionCallbacks>
      </div>
    );
  };

  render() {
    const { videoIds, itemsCount, isRTL, queryWidgetWidth } = this.props;

    const itemClassNames = queryWidgetWidth([
      { minWidth: 640, className: styles.itemMin640 },
      { minWidth: 1280, className: styles.itemMin1280 },
    ]);

    const arrowClassNames = queryWidgetWidth([
      { minWidth: 640, className: styles.arrowMin640 },
      { minWidth: 1280, className: styles.arrowMin1280 },
    ]);

    return (
      <div className={styles.container}>
        <Slider
          items={videoIds}
          itemsCount={itemsCount}
          renderItem={this.renderItem}
          arrowClassName={classNames(styles.arrow, arrowClassNames)}
          itemClassName={classNames(styles.item, itemClassNames)}
          loadMore={this.loadMore}
          isRTL={isRTL}
        />
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withQueryWidgetWidth(VideoList));
