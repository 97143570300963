import { GqlChannelPricingType } from '../apollo/generated/graphql';
import { useChannel } from './useChannel';

type PricingPlanChannel = {
  purchaseOrRentDisabled: boolean;
  isPricingPlanChannel: true;
  isFreeChannel: false;
  isSubscriptionChannel: false;
};

type FreeChannel = {
  purchaseOrRentDisabled: false;
  isPricingPlanChannel: false;
  isFreeChannel: true;
  isSubscriptionChannel: false;
};

type SubscriptionChannel = {
  purchaseOrRentDisabled: boolean;
  isPricingPlanChannel: false;
  isFreeChannel: false;
  isSubscriptionChannel: true;
  price: number;
  currency: string;
};

type ChannelPricing = PricingPlanChannel | FreeChannel | SubscriptionChannel;

export const useChannelPricing = (): ChannelPricing => {
  const channel = useChannel();

  const { pricing } = channel;

  for (const { enabled, type, price, currency, required } of pricing) {
    if (enabled) {
      if (type === GqlChannelPricingType.PricingPlan) {
        return {
          isPricingPlanChannel: true,
          isFreeChannel: false,
          isSubscriptionChannel: false,
          purchaseOrRentDisabled: required,
        };
      }

      if (type === GqlChannelPricingType.Subscription && price) {
        return {
          isSubscriptionChannel: true,
          isFreeChannel: false,
          purchaseOrRentDisabled: required,
          isPricingPlanChannel: false,
          price,
          currency,
        };
      }
    }
  }

  return {
    isFreeChannel: true,
    isPricingPlanChannel: false,
    purchaseOrRentDisabled: false,
    isSubscriptionChannel: false,
  };
};
