import { useTranslation } from '@wix/yoshi-flow-editor';
import { useCallback } from 'react';

export function useFormatMessageWithPrice() {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const formatMessageWithPrice = useCallback(
    (messageId: string, price: number, currency: string) => {
      const formattedPrice = new Intl.NumberFormat(language, {
        style: 'currency',
        currency,
        minimumFractionDigits: price % 1 > 0 ? 2 : 0,
        minimumIntegerDigits: 1,
      }).format(price);

      return t<string>(messageId, {
        price: formattedPrice,
      });
    },
    [t, language],
  );

  return {
    formatMessageWithPrice,
  };
}
