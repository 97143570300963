import { createAction } from 'redux-actions';
import { INITIATORS } from '../../../utils/initiators';
import { openMobileOverlay } from '../../../utils/open-overlay-base';
import { createProxy } from '../../../worker/lib';

export const OPEN_SUBSCRIBE_OVERLAY = 'OPEN.SUBSCRIBE-OVERLAY';
const OPEN_SUBSCRIBE_OVERLAY_ACTION = createAction(OPEN_SUBSCRIBE_OVERLAY);

export const OPEN_BUY_VIDEO_OVERLAY = 'OPEN.BUY-VIDEO-OVERLAY';
const OPEN_BUY_VIDEO_OVERLAY_ACTION = createAction(OPEN_BUY_VIDEO_OVERLAY);

export const OPEN_PAYMENT_COMPLETE_OVERLAY = 'OPEN.PAYMENT_COMPLETE_OVERLAY';

export const openSubscribeOverlayFromWidget = () =>
  openSubscribeOverlay(INITIATORS.WIDGET);

export const openBuyVideoOverlayFromWidget = (videoId) =>
  openBuyVideoOverlay(videoId, INITIATORS.WIDGET);

export const openSubscribeOverlay = createProxy(
  'openSubscribeOverlay',
  (initiator = '') => {
    return function (dispatch) {
      dispatch(OPEN_SUBSCRIBE_OVERLAY_ACTION());

      dispatch(openMobileOverlay(`#/channel/subscribe/${initiator}`));
    };
  },
);

export const openBuyVideoOverlay = createProxy(
  'openBuyVideoOverlay',
  (videoId, initiator = '') => {
    return function (dispatch) {
      dispatch(OPEN_BUY_VIDEO_OVERLAY_ACTION());

      dispatch(openMobileOverlay(`#/video/${videoId}/purchase/${initiator}`));
    };
  },
);
