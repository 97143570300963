import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { loadVODPlayer } from '../../../utils/load-player';
import { logWidgetSystem } from '../../../worker/actions/bi';
import { getVideoPreviewUrls } from '../../../api/public/channel/channel';
import getVidiUrls from '../../../selectors/vidi-urls';

import { VideoViewMode } from 'playable/dist/src/modules/ui/screen/types';

import styles from './video-preview.scss';

const getPreviewUrlsAction = (channelId, instance) => () =>
  getVideoPreviewUrls(channelId, instance);

const mapStateToProps = null;

const mapDispatchToProps = {
  logWidgetSystem,
  getPreviewUrlsAction,
};

const VideoPreview = connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  class VideoPreview extends React.Component {
    static propTypes = {
      channelId: PropTypes.string,
      instance: PropTypes.string,
      poster: PropTypes.node,
      width: PropTypes.number,
      height: PropTypes.number,
    };

    constructor(props) {
      super(props);

      this.state = {
        vidiUrls: [],
        uploadingError: false,
        VODPlayer: null,
        isReady: false,
      };

      this.loadUrls();
    }

    componentDidMount() {
      loadVODPlayer().then((VODPlayer) => {
        this.setState({ VODPlayer });
      });
    }

    setReady = () => {
      this.setState({ isReady: true });
    };

    loadUrls() {
      const { channelId, instance } = this.props;

      this.props.logWidgetSystem('channel.cover.videoPreview.loadUrls.started');
      this.props.getPreviewUrlsAction(channelId, instance).then(
        (videoUrls) => {
          this.props.logWidgetSystem(
            'channel.cover.videoPreview.loadUrls.loaded',
          );
          this.setState({
            vidiUrls: getVidiUrls({ videoUrls }),
            uploadingError: false,
          });
        },
        () => {
          this.props.logWidgetSystem(
            'channel.cover.videoPreview.loadUrls.failed',
          );
          this.setState({ uploadingError: true });
        },
      );
    }

    shouldShowPoster() {
      const { uploadingError, isReady } = this.state;
      return !isReady || uploadingError;
    }

    render() {
      const { width, height, poster } = this.props;
      const { vidiUrls, VODPlayer } = this.state;

      const posterClassNames = classNames(styles.poster, {
        [styles.hide]: !this.shouldShowPoster(),
      });

      return (
        <div className={styles['player-container']}>
          <div className={posterClassNames}>{poster}</div>
          {VODPlayer && (
            <VODPlayer
              width={width}
              height={height}
              vidiUrls={vidiUrls}
              onPlay={this.setReady}
              canShowTitle={false}
              hideMainUI={true}
              loader={false}
              videoViewMode={VideoViewMode.FILL}
              muted
              autoPlay
              loop
            />
          )}
        </div>
      );
    }
  },
);

export default VideoPreview;
