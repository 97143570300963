import _ from 'lodash';
import classnames from 'classnames';
import React from 'react';

import PropTypes from 'prop-types';

import styles from './icon.scss';
import fontelloIconsStyles from '../../../../../../assets/fonts/fontello/css/fontello-codes.css';

const iconStyles = { ...styles, ...fontelloIconsStyles };

export default class Icon extends React.PureComponent {
  static propTypes = {
    size: PropTypes.number,
    name: PropTypes.string,
    className: PropTypes.string,
    getRef: PropTypes.func,
    dataHook: PropTypes.string,
    role: PropTypes.string,
  };

  static defaultProps = {
    getRef: _.noop,
    role: 'presentation',
  };

  get iconStyle() {
    const { size } = this.props;
    if (!size) {
      return null;
    }

    return {
      fontSize: size,
    };
  }

  render() {
    const { name, getRef, dataHook, className, role } = this.props;
    const props = _.omit(this.props, [
      'styles',
      'styleName',
      'intl',
      'settings',
      'getRef',
      'biEvent',
      'biError',
      'closePortal',
      'children',
      'dataHook',
    ]);

    return (
      <span
        ref={getRef}
        data-hook={dataHook}
        aria-hidden={role === 'presentation' ? 'true' : 'false'}
        {...props}
        className={
          classnames(className, iconStyles.icon, iconStyles[`icon--${name}`]) ||
          null
        }
        style={this.iconStyle}
      />
    );
  }
}

export class RoundedIcon extends React.PureComponent {
  render() {
    const props = _.omit(this.props, 'styles');

    return (
      <Icon
        {...props}
        className={classnames(props.className, styles['rounded-icon'])}
      />
    );
  }
}
