import { createContext, useContext } from 'react';

export type SiteInfoContext = {
  currentPageId: string;
  instance: string;
  instanceId: string;
  biToken: string;
  compId: string;
  siteUrl: string;
  query: {
    ['wix-vod-comp-id']?: string;
    ['wix-vod-video-id']?: string;
  };
};

const SiteInfoContext = createContext<SiteInfoContext | null>(null);

export const useSiteInfo = (): SiteInfoContext => {
  const siteInfoContext = useContext(SiteInfoContext);
  if (siteInfoContext === null) {
    throw Error('The hook must be called inside <SiteInfoProvider> component');
  }
  return siteInfoContext;
};

export const SiteInfoProvider = SiteInfoContext.Provider;
