import { createAction } from 'redux-actions';
import { closeSlideMenu } from '../../../mobile/redux/actions/slide-menu';
import { openMobileOverlay } from '../../../utils/open-overlay-base';
import { createProxy } from '../../../worker/lib';

export const VISIT_CHANNEL = 'CLIENT.VISIT-CHANNEL';
const VISIT_CHANNEL_ACTION = createAction(VISIT_CHANNEL);

export const OPEN_VIDEO_PAGE = 'CLIENT.OPEN.VIDEO-PAGE';
const OPEN_VIDEO_PAGE_ACTION = createAction(OPEN_VIDEO_PAGE);

export const visitChannel = createProxy('visitChannel', () => {
  return function (dispatch) {
    dispatch(VISIT_CHANNEL_ACTION());
    dispatch(closeSlideMenu());

    dispatch(openMobileOverlay());
  };
});

export const openVideoPage = createProxy(
  'openVideoPage',
  (videoId, onClose) => {
    return function (dispatch) {
      dispatch(OPEN_VIDEO_PAGE_ACTION());
      dispatch(closeSlideMenu());

      dispatch(openMobileOverlay(`#/video/${videoId}`, onClose));
    };
  },
);
