import React, { ComponentType, useMemo } from 'react';
import {
  useFedopsLogger,
  withFedopsLogger,
  InjectedFedopsLoggerProps,
} from '@wix/yoshi-flow-editor';

export class Interactions {
  private readonly interactions: Record<string, number> = {};

  constructor(private readonly logger: InjectedFedopsLoggerProps['fedops']) {}

  start(interactionName: string) {
    let count = this.interactions[interactionName] || 0;
    count++;
    this.logger.interactionStarted(interactionName);
    this.interactions[interactionName] = count;
  }

  end(interactionName: string) {
    let count = this.interactions[interactionName];

    if (count > 0) {
      count--;
      this.logger.interactionEnded(interactionName);
      this.interactions[interactionName] = count;
    }
  }
}

export type WithInteractionProps<P> = InjectedFedopsLoggerProps & {
  interactions: Interactions;
} & P;

export function withFedopsInteractions<P>(
  Component: ComponentType<WithInteractionProps<P>>,
) {
  return withFedopsLogger<P & InjectedFedopsLoggerProps>((props) => {
    const { fedops } = props;
    const interactions = useMemo(() => new Interactions(fedops), [fedops]);

    return <Component interactions={interactions} {...props} />;
  });
}

export function useFedopsInteractions() {
  const fedops = useFedopsLogger();

  return useMemo(() => new Interactions(fedops), [fedops]);
}

const playEvents = {
  computer: 'COMPUTER_PLAY',
  vimeo: 'VIMEO_PLAY',
  youtube: 'YOUTUBE_PLAY',
  facebook: 'FACEBOOK_PLAY',
  // By layout
  grid: 'GRID_PLAY',
  classic: 'CLASSIC_PLAY',
  compact: 'COMPACT_PLAY',
  'full-screen-modal': 'FULL_SCREEN_MODAL_PLAY',
  // By trigger
  auto: 'AUTO_PLAY',
  click: 'CLICK_PLAY',
  open: 'OPEN_PLAY',
} as const;

// STRIP layout and SLIDER layout does not support inframe play.
// Such interaction is impossible.
type PlayInteractionOptions = {
  source: 'computer' | 'vimeo' | 'youtube' | 'facebook';
  layout: 'grid' | 'classic' | 'compact' | 'full-screen-modal';
  trigger: 'auto' | 'click' | 'open';
};

export function startPlayInteraction(
  interactions: Interactions,
  options: PlayInteractionOptions,
) {
  interactions.start('PLAY');
  interactions.start(playEvents[options.source]);
  interactions.start(playEvents[options.layout]);
  interactions.start(playEvents[options.trigger]);
}

export function endPlayInteraction(
  interactions: Interactions,
  options: Omit<PlayInteractionOptions, 'trigger'>,
) {
  interactions.end('PLAY');
  interactions.end(playEvents[options.source]);
  interactions.end(playEvents[options.layout]);
  // Try to finish all triggers. We don't send not started events. Shouldn't be a problem
  interactions.end(playEvents.click);
  interactions.end(playEvents.auto);
  interactions.end(playEvents.open);
}
