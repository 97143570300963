import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Thumbnail,
  Content,
  Buttons,
  ThumbnailLiveLabel,
} from '@wix/wix-vod-shared/components';
import { withTranslation } from '@wix/yoshi-flow-editor';
import styles from './video-thumbnail.scss';
import { TEXT_ALIGNMENT_VALUES } from '@wix/wix-vod-constants/app-settings';

import {
  canShowVideoListItemTitle,
  getVideoListPublisherVisibility,
  canShowVideoListItemDuration,
  canShowVideoListItemDescription,
  canShowVideoListItemContentBelow,
  isButtonsShownOnHover,
  isButtonsTextShown,
  getTextAlignment,
  getUseOptimalFontSizes,
  getTitleFont,
  getTextFont,
  getLocale,
  isRTL,
} from '../../selectors/app-settings';

import {
  getVideoCoverUrl,
  getNormalizedCachedDimensions,
  isLiveVideo,
  getResizedImageUrl,
  isStreamingLive,
  isScheduledLive,
  isPendingLiveStream,
} from '@wix/wix-vod-shared/common';
import { createGetPlayButtonConfig } from '../../layouts/compact/components/player-overlay/ui-selectors/video-overlay/play/button-config';
import { createGetPaidAccessButtonConfig } from '../../layouts/compact/components/player-overlay/ui-selectors/video-overlay/paid-access/button-config';
import formatMessageWithPrice from '../../utils/format-message-with-price';

const alignmentMap = {
  [TEXT_ALIGNMENT_VALUES.LEFT]: 'left',
  [TEXT_ALIGNMENT_VALUES.CENTER]: 'center',
  [TEXT_ALIGNMENT_VALUES.RIGHT]: 'right',
};

const mapStateToProps = () => {
  const getPlayButtonConfig = createGetPlayButtonConfig();
  const getPaidAccessButtonConfig = createGetPaidAccessButtonConfig();
  return (state, ownProps) => ({
    playButtonConfig: getPlayButtonConfig(state, ownProps),
    paidAccessButtonConfig: getPaidAccessButtonConfig(state, ownProps),
    showTitle: canShowVideoListItemTitle(state),
    showDuration: canShowVideoListItemDuration(state),
    showPublisher: getVideoListPublisherVisibility(state),
    showDescription: canShowVideoListItemDescription(state),
    showContent: canShowVideoListItemContentBelow(state),
    isButtonsShownOnHover: isButtonsShownOnHover(state),
    locale: getLocale(state),
    isButtonsTextShown: isButtonsTextShown(state),
    isPendingLiveStream: isPendingLiveStream(ownProps.videoItem),
    textAlignment: getTextAlignment(state),
    isOptimalFontSizes: getUseOptimalFontSizes(state),
    titleFont: getTitleFont(state),
    textFont: getTextFont(state),
    isRTL: isRTL(state),
  });
};

export default withTranslation()(
  connect(mapStateToProps)(
    class VideoThumbnail extends React.Component {
      static propTypes = {
        width: PropTypes.number.isRequired,
        videoItem: PropTypes.object.isRequired,
        breakpoints: PropTypes.object,
        playButtonConfig: PropTypes.object,
        paidAccessButtonConfig: PropTypes.object,
        onPlayWithoutPreviewRequest: PropTypes.func,
        showTitle: PropTypes.bool.isRequired,
        showDuration: PropTypes.bool.isRequired,
        showPublisher: PropTypes.bool.isRequired,
        showDescription: PropTypes.bool.isRequired,
        isLoading: PropTypes.bool,
        isButtonsShownOnHover: PropTypes.bool.isRequired,
        isPendingLiveStream: PropTypes.bool.isRequired,
        locale: PropTypes.string,
        isButtonsTextShown: PropTypes.bool.isRequired,
        isContentFocusable: PropTypes.bool.isRequired,
        isOptimalFontSizes: PropTypes.bool.isRequired,
        textAlignment: PropTypes.number.isRequired,
        dataHook: PropTypes.string,
        titleFont: PropTypes.object,
        textFont: PropTypes.object,
        isRTL: PropTypes.bool,
        useResponsiveThumbnail: PropTypes.bool,
        renderThumbnailContent: PropTypes.func,
      };

      renderThumbnailContent() {
        const {
          videoItem,
          onPlayWithoutPreviewRequest,
          showTitle,
          showPublisher,
          showDescription,
          showContent,
          isContentFocusable,
          textAlignment,
          isOptimalFontSizes,
          isRTL,
          titleFont,
          textFont,
          renderThumbnailContent,
          width,
        } = this.props;

        if (!showContent) {
          return null;
        }

        const title = showTitle ? videoItem.title : '';
        const description = showDescription ? videoItem.description : '';
        const publisher = showPublisher ? videoItem.publisher : '';
        const alignment = alignmentMap[textAlignment];

        if (renderThumbnailContent) {
          return renderThumbnailContent({
            title,
            description,
            publisher,
            alignment,
            onClick: onPlayWithoutPreviewRequest,
          });
        }

        return (
          <Content
            width={width}
            title={title}
            description={description}
            publisher={publisher}
            onClick={onPlayWithoutPreviewRequest}
            className={styles.content}
            titleClassName={styles.title}
            textClassName={styles.text}
            isFocusable={isContentFocusable}
            isOptimalFontSizes={isOptimalFontSizes}
            alignment={alignment}
            titleFont={titleFont}
            textFont={textFont}
            isRTL={isRTL}
          />
        );
      }

      renderThumbnailButtons() {
        const { isRTL } = this.props;

        const playButtonProps = this.getPlayButtonProps();

        const thumbnailButtonsProps = {
          ...playButtonProps,
          ...this.getBuyButtonProps(),
        };

        return (
          <Buttons
            showButtonsText={this.props.isButtonsTextShown}
            isFocusable={this.props.isContentFocusable}
            {...thumbnailButtonsProps}
            className={styles.buttons}
            isRTL={isRTL}
          />
        );
      }

      renderLiveLabel() {
        const {
          videoItem,
          locale,
          isContentFocusable,
          isPendingLiveStream,
          isRTL,
        } = this.props;

        if (!isLiveVideo(videoItem)) {
          return null;
        }

        return (
          <ThumbnailLiveLabel
            videoTitle={videoItem.title}
            startTime={videoItem.dateStartLive}
            isStreaming={isStreamingLive(videoItem)}
            isScheduled={isScheduledLive(videoItem)}
            locale={locale}
            isFocusable={isContentFocusable}
            isPendingLiveStream={isPendingLiveStream}
            liveText={this.props.t('shared.live')}
            pendingLiveText={this.props.t('shared.pending-live')}
            scheduledSoonAriaLabel={this.props.t(
              'widget.accessibility.scheduled-soon-label',
            )}
            scheduledAriaLabel={this.props.t(
              'widget.accessibility.scheduled-label',
            )}
            startsInAriaLabel={this.props.t('widget.accessibility.starts-in')}
            reminderAriaLabel={this.props.t('widget.accessibility.reminder')}
            addReminderText={this.props.t('shared.add-reminder')}
            isRTL={isRTL}
          />
        );
      }

      getPlayButtonProps() {
        const { playButtonConfig } = this.props;

        if (!playButtonConfig) {
          return {
            showPlayButton: false,
          };
        }

        return {
          showPlayButton: true,
          playButtonText: this.props.t(playButtonConfig.translationData.props),
          onPlayClick: this.handlePlayClick,
        };
      }

      handlePlayClick = () => {
        const { playButtonConfig } = this.props;

        if (!playButtonConfig) {
          return;
        }

        playButtonConfig.callback();
      };

      getBuyButtonProps() {
        const { paidAccessButtonConfig } = this.props;

        if (!paidAccessButtonConfig) {
          return {
            showBuyButton: false,
          };
        }

        const { currency, price } =
          paidAccessButtonConfig.translationData.priceModel;

        return {
          showBuyButton: true,
          currency,
          buyButtonText: formatMessageWithPrice(
            paidAccessButtonConfig.translationData.props,
            paidAccessButtonConfig.translationData.priceModel,
          ),

          buyAriaLabel: this.props.t(
            paidAccessButtonConfig.translationData.props,
            {
              price: `${currency} ${price}`,
            },
          ),

          onBuyClick: paidAccessButtonConfig.callback,
        };
      }

      getBackgroundSrc() {
        const { videoItem } = this.props;
        return getVideoCoverUrl(videoItem);
      }

      getBackgroundUrl() {
        const { videoItem, width } = this.props;
        const src = getVideoCoverUrl(videoItem);
        const dimensions = getNormalizedCachedDimensions(src, {
          containerWidth: width,
          containerHeight: (width * 9) / 16,
        });

        return getResizedImageUrl({
          url: src,
          width: dimensions[0],
          height: dimensions[1],
        });
      }

      render() {
        const {
          width,
          breakpoints,
          isButtonsShownOnHover,
          videoItem,
          showDuration,
          isLoading,
          isContentFocusable,
          dataHook,
          isRTL,
          useResponsiveThumbnail,
        } = this.props;

        const duration =
          showDuration && videoItem.durationSec ? videoItem.durationStr : '';

        return (
          <Thumbnail
            breakpoints={breakpoints}
            width={width}
            useResponsiveThumbnail={useResponsiveThumbnail || breakpoints}
            videoTitle={videoItem.title}
            isLoading={isLoading}
            dataHook={dataHook}
            onClick={this.handlePlayClick}
            overlayClassName={styles.overlay}
            backgroundUrl={this.getBackgroundUrl()}
            backgroundSrc={this.getBackgroundSrc()}
            buttons={this.renderThumbnailButtons()}
            content={this.renderThumbnailContent()}
            duration={duration}
            liveLabel={this.renderLiveLabel()}
            showButtonsOnHover={isButtonsShownOnHover}
            showLiveLabel={isLiveVideo(videoItem)}
            isFocusable={isContentFocusable}
            ariaLabel={videoItem.title}
            isRTL={isRTL}
          />
        );
      }
    },
  ),
);
