import { parseCards, ParsedVideoCard } from '@wix/wix-vod-shared/common';
import { toError } from '../../parsers/transport-error';
import { getPublicApi } from '../index';

export type GetVideoCardsResponse = {
  data: ParsedVideoCard[];
};

export const getVideoCards =
  (channelId: string, videoId: string) =>
  (): Promise<GetVideoCardsResponse> => {
    const { oldPublicVideoCardsService } = getPublicApi();
    const promise = oldPublicVideoCardsService.getList(channelId, videoId);

    return promise
      .then((response) => ({
        ...response,
        data: parseCards(response.data),
      }))
      .catch(toError);
  };
