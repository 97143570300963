import { getWixSDK } from '../../utils/wix-sdk';
import { getCompId } from '../../utils/comp-id';
import { getInstance } from '../hydrated-data/hydrated-data';
import { URL } from '@wix/wix-vod-shared/common';

import {
  CREATE_ORDER,
  INIT_CHECKOUT,
  SELECT_METHOD,
  setError,
} from './payment.constants';
import { createAsyncProxy } from '../../worker/lib';
import { getPublicApi } from '../../api/public';

const getSiteDeepUrl = () =>
  getWixSDK()
    .Settings.getSiteInfo()
    .then((siteInfo) => {
      const { protocol, hostname, pathname } = new URL(siteInfo.url, {
        skipValidation: true,
      });

      return `${protocol}//${hostname}${pathname}`;
    });

export * from './payment.constants';

export function createOrder(paymentType, channelId, videoId) {
  return (dispatch, getState) => {
    const state = getState();
    const instance = getInstance(state);
    const compId = getCompId();

    dispatch(CREATE_ORDER.START({ paymentType, channelId, videoId }));
    return getSiteDeepUrl()
      .then((siteUrl) =>
        getPublicApi().purchase.createOrder(
          videoId,
          channelId,
          paymentType,
          siteUrl,
          instance,
          compId,
        ),
      )
      .then(
        (orderId) => {
          dispatch(CREATE_ORDER.SUCCESS({ orderId }));
        },
        (error) => {
          dispatch(CREATE_ORDER.FAIL(error));
          return Promise.reject(error);
        },
      );
  };
}

export const cancelOrder =
  (currentChannelId, siteUrl, instance) => async (dispatch) => {
    const publicApi = getPublicApi();
    await publicApi.purchase.cancelOrder(currentChannelId, siteUrl, instance);
  };

export const selectPaymentMethod = (paymentMethod) =>
  SELECT_METHOD(paymentMethod);

export const initPayment = createAsyncProxy(
  'payment.initPayment',
  ({ paymentType, channelId, videoId }) =>
    async (dispatch) => {
      try {
        dispatch(INIT_CHECKOUT.START());
        await dispatch(createOrder(paymentType, channelId, videoId));
        dispatch(INIT_CHECKOUT.SUCCESS());
      } catch (error) {
        dispatch(INIT_CHECKOUT.FAIL(error));
        dispatch(setError(error));
      }
    },
);
