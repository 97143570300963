import { isVerticalLayoutMobile } from '../selectors/app-settings';
import { getVideoIds } from '../redux/lazy-channel-videos/selectors';
import { clearHydratedData } from '../redux/hydrated-data/hydrated-data';
import { getPublicChannel } from '../redux/actions/channel/get-public';
import { getPaymentInfo } from '../redux/actions/channel/payment';
import { getMainVideoId } from '@wix/wix-vod-shared/widget';
import {
  loadMoreVideoPages,
  prependVideoIds,
} from '../redux/lazy-channel-videos/actions';
import { getPublicVideo } from '../redux/actions/channel/video/get';
import { selectVideo } from '../redux/actions/select-video';
import _ from 'lodash';
import { getVideoIdFromUrl } from '../selectors/query-params';
import { getVideoById } from '../selectors/videos';
import { openVideoPage } from '../redux/actions/mobile-overlay-openners/main-openners';
import { getCurrentChannelId } from '../selectors/channels';
import { ActionCreator } from '../redux/redux.types';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from '../redux/root-state';
import { getCurrentVideoId } from '../selectors/video';

export const setInitialState: ActionCreator =
  () => async (dispatch, getState) => {
    const state = getState();
    const channelId = getCurrentChannelId(state);
    const singleVideoId = getCurrentVideoId(state);
    const videoIdFromUrl = getVideoIdFromUrl(state);

    // we should set as pre-populated data when create a redux store with channelId always present instead of dispatching
    // dozens of actions on init, this way we might be able to guarantee that currentChannelId is always string and not null
    await loadInitialNeededData(dispatch, channelId as string, videoIdFromUrl);

    if (singleVideoId) {
      await loadDataForSingleVideo(
        dispatch,
        channelId as string,
        singleVideoId,
      );
    } else {
      await loadPageVideos(dispatch, state, getState);
    }

    dispatch(clearHydratedData());
  };

export const openVideoFromUrlIfExists: ActionCreator =
  () => (dispatch, getState) => {
    const state = getState();
    const videoIdFromUrl = getVideoIdFromUrl(state);
    const videoFromUrlExists = getVideoById(state, videoIdFromUrl);
    if (videoFromUrlExists) {
      dispatch(openVideoPage(videoIdFromUrl));
    }
  };

const loadDataForSingleVideo = async (
  dispatch: ThunkDispatch<RootState, any, any>,
  channelId: string,
  singleVideoId: string,
) => {
  await dispatch(getPublicVideo(channelId, singleVideoId));
  dispatch(prependVideoIds(singleVideoId));
  dispatch(selectVideo(singleVideoId));
};

const loadInitialNeededData = async (
  dispatch: ThunkDispatch<RootState, any, any>,
  channelId: string,
  videoIdFromUrl: string | null,
) => {
  const initDataToBeLoaded = [
    dispatch(getPublicChannel(channelId)),
    dispatch(getPaymentInfo(channelId)),
  ];

  if (videoIdFromUrl) {
    initDataToBeLoaded.push(
      dispatch(getPublicVideo(channelId, videoIdFromUrl)),
    );
  }

  return Promise.all(initDataToBeLoaded);
};

const loadPageVideos = async (
  dispatch: ThunkDispatch<RootState, any, any>,
  state: RootState,
  getState: () => RootState,
) => {
  const pagesCount = isVerticalLayoutMobile(state) ? 2 : undefined;
  await dispatch(loadMoreVideoPages(pagesCount));

  const videoIds = getVideoIds(getState());
  const mainVideoId = getMainVideoId(getState());

  if (!_.includes(videoIds, mainVideoId)) {
    dispatch(prependVideoIds(mainVideoId));
  }
};
