import {
  applyMiddleware,
  createStore,
  compose,
  Middleware,
  StoreEnhancer,
  PreloadedState,
} from 'redux';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
import biMiddleware from './bi/bi-middleware';
import lazyChannelVideosMiddleware from './redux/middleware/lazy-channel-videos/lazy-channel-videos-middleware';
import biHandlers from './redux/middleware/bi-middleware/bi-handlers';
import { initNotForPreview } from './utils/not-for-preview';

import { CreateRootReducer } from './redux/root-reducer';
import { RootState } from './redux/root-state';
import { History } from 'history';
import { Handlers } from './worker/controller-handlers/handlers';

export const createConfigureStore =
  <S extends RootState = RootState>({
    createRootReducer,
    createRootReducerPath,
  }: {
    createRootReducer: CreateRootReducer<S>;
    createRootReducerPath: string;
  }) =>
  ({
    initialState = {},
    middlewares: additionalMiddlewares = [],
    history,
    handlers,
  }: {
    history: History;
    initialState?: Partial<S>;
    middlewares?: Middleware[];
    handlers: Handlers;
  }) => {
    const storage = {};
    const middlewares: Middleware[] = [
      thunk.withExtraArgument({ storage, handlers }),
      routerMiddleware(history),
      lazyChannelVideosMiddleware,
      biMiddleware(biHandlers),
    ];

    middlewares.push(...additionalMiddlewares);

    const currentWindow =
      typeof window !== 'undefined'
        ? (window as unknown as Window & {
            __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: typeof compose;
          })
        : undefined;
    const composeEnhancers =
      typeof currentWindow !== 'undefined' &&
      currentWindow.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? currentWindow.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        : compose;

    const middleware: StoreEnhancer<S> = composeEnhancers(
      applyMiddleware(...middlewares),
    ) as unknown as StoreEnhancer<S>;

    // Create final store and subscribe router in debug env ie. for devtools
    const { router, ...restInitialState } = initialState;
    const store = middleware(createStore)(
      createRootReducer({ history }),
      restInitialState as PreloadedState<S>,
    );

    if (module.hot) {
      module.hot.accept(createRootReducerPath, () => {
        const createNextRootReducer = require(createRootReducerPath).default;
        store.replaceReducer(createNextRootReducer({ history }));
      });
    }

    initNotForPreview(store);

    return store;
  };
