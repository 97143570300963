import { useTranslation } from '@wix/yoshi-flow-editor';
import { useChannelPricing, useFormatMessageWithPrice } from '.';
import { useWorker } from './useWorker';
import { useChannelAccessAction } from './useChannelAccessAction';
import { AccessAction } from '../types/enums';

type UseChannelAccessButton =
  | {
      text: string;
      handler(): void;
    }
  | undefined;

export const useChannelAccessButton = (): UseChannelAccessButton => {
  const { t } = useTranslation();
  const channelPricing = useChannelPricing();
  const { formatMessageWithPrice } = useFormatMessageWithPrice();
  const {
    modals: { openDesktopSubscription },
  } = useWorker();
  const accessAction = useChannelAccessAction();

  if (accessAction === AccessAction.PricingPlan) {
    return {
      text: t('widget.mobile.overlay-button.subscribe.short'),
      handler: () => openDesktopSubscription(),
    };
  }

  if (
    accessAction === AccessAction.Subscribe &&
    channelPricing.isSubscriptionChannel
  ) {
    return {
      text: formatMessageWithPrice(
        'widget.channel-actions.subscribe',
        channelPricing.price,
        channelPricing.currency,
      ),
      handler: () => openDesktopSubscription(),
    };
  }
};
