import { useSelectedVideo } from '.';
import { useVideoPlayButton } from './useVideoPlayButton';

type UseChannelPlayButton =
  | {
      canPlay: false;
      text: undefined;
    }
  | {
      canPlay: true;
      text: string;
    };

export const useChannelPlayButton = (): UseChannelPlayButton => {
  const { selectedVideo } = useSelectedVideo();
  const { canPlay, playText } = useVideoPlayButton(selectedVideo);

  return canPlay
    ? {
        canPlay: true,
        text: playText,
      }
    : {
        canPlay: false,
        text: undefined,
      };
};
