import React from 'react';
import { OldWidgetProps } from '../index';

const WidgetPropsContext = React.createContext<OldWidgetProps | undefined>(
  undefined,
);

export const WidgetPropsProvider = WidgetPropsContext.Provider;

export function withWidgetProps(
  selector: (props: OldWidgetProps) => { [key: string]: any },
) {
  return (Component: React.JSXElementConstructor<any>) => (props: any) =>
    (
      <WidgetPropsContext.Consumer>
        {(widgetProps) =>
          widgetProps ? (
            <Component {...props} {...selector(widgetProps)} />
          ) : null
        }
      </WidgetPropsContext.Consumer>
    );
}
