import { useCallback, useEffect } from 'react';
import { useEnvironment, useWixSdk, useBi } from '@wix/yoshi-flow-editor';
import { widgetMigrateIdsToSettingsParams } from '@wix/bi-logger-video/v2';
import { ISettingsParam } from '@wix/tpa-settings';
import { useSettings, useStyles } from '@wix/tpa-settings/react';
import settingsParams from '../../../settingsParams';
import stylesParams from '../../../stylesParams';
import { getIdsFromFonts, getIdsFromSettings } from './ids';
import { useSelector } from 'react-redux';
import { getSiteOwnerId } from '../redux/hydrated-data/hydrated-data';

function useMigrateIdsToSettingsParams(
  fontsChannelId: string,
  fontsVideoId: string,
) {
  const { Wix } = useWixSdk();
  const { isEditor } = useEnvironment();
  const settings = useSettings();
  const bi = useBi();

  const useIdsFromSettings = settings.get(settingsParams.useIdsFromSettings);

  const setData = useCallback(
    (param: ISettingsParam, value: any) =>
      new Promise((resolve, reject) => {
        if (Wix) {
          // use Wix.Data directly to avoid CantModifyParamError when update settings from the widget 😁
          // https://github.com/wix-private/tpa-settings/blob/fe32e60e08ec034e13e60cceb182dc95544fa598/packages/tpa-settings/src/contexts/SettingsContext/SettingsProvider.tsx#L182
          Wix.Data.Public.set(
            param.key,
            value,
            {
              scope: Wix.Data.SCOPE.COMPONENT,
            },
            () => resolve(null),
            () => reject(new Error(`Failed to set ${param.key}`)),
          );
        }
      }),
    [Wix],
  );

  const migrateIdsToSettingsParams = useCallback(async () => {
    try {
      await setData(settingsParams.channelId, fontsChannelId);
      await setData(settingsParams.videoId, fontsVideoId);
      await setData(settingsParams.useIdsFromSettings, true);
      bi.report(widgetMigrateIdsToSettingsParams({}));
    } catch (e) {}
  }, [setData, fontsChannelId, fontsVideoId, bi]);

  useEffect(() => {
    if (isEditor && !useIdsFromSettings) {
      migrateIdsToSettingsParams();
    }
  }, [isEditor, migrateIdsToSettingsParams, useIdsFromSettings]);
}

export function useIds() {
  const settings = useSettings();
  const styles = useStyles();
  const siteOwnerId = useSelector(getSiteOwnerId);
  const useIdsFromSettings = settings.get(settingsParams.useIdsFromSettings);
  const idsFromFonts = getIdsFromFonts(
    styles.get(stylesParams.channelId),
    styles.get(stylesParams.videoId),
    siteOwnerId,
  );
  const idsFromSettings = getIdsFromSettings(
    settings.get(settingsParams.channelId),
    settings.get(settingsParams.videoId),
    siteOwnerId,
  );

  useMigrateIdsToSettingsParams(idsFromFonts.channelId, idsFromFonts.videoId);

  if (useIdsFromSettings) {
    return idsFromSettings;
  }

  return idsFromFonts;
}
