import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import styles from './slider.scss';
import classNames from 'classnames';

const TIMEOUT = 150;

class Slider extends React.Component {
  static propTypes = {
    items: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    itemsCount: PropTypes.number.isRequired,
    renderItem: PropTypes.func,
    getItemKey: PropTypes.func,
    subscribeOnParentScroll: PropTypes.func,
    unSubscribeFromParentScroll: PropTypes.func,
    loadMore: PropTypes.func,
    arrowClassName: PropTypes.string,
    itemClassName: PropTypes.string,
    vertical: PropTypes.bool,
    isRTL: PropTypes.bool,
  };

  static defaultProps = {
    loadMore: _.noop,
    subscribeOnParentScroll: _.noop,
    unSubscribeFromParentScroll: _.noop,
  };

  constructor(props) {
    super(props);

    this.containerRef = React.createRef();
    this.state = {
      lastVisibleItemIndex: 0,
      lastItemIndex: 0,
      visibleCount: 5,
    };
  }

  componentDidMount() {
    const { subscribeOnParentScroll } = this.props;

    if (subscribeOnParentScroll) {
      subscribeOnParentScroll(this.handleParentScroll);
    }
    window.addEventListener('resize', this.checkVisibleItems);
    this.checkVisibleItems();
  }

  componentDidUpdate(prevProps) {
    const { itemsCount } = this.props;
    const { itemsCount: prevItemsCount } = prevProps;

    if (itemsCount !== prevItemsCount) {
      this.checkVisibleItems();
    }
  }

  componentWillUnmount() {
    const { unSubscribeFromParentScroll } = this.props;

    if (unSubscribeFromParentScroll) {
      unSubscribeFromParentScroll(this.handleParentScroll);
    }
    window.removeEventListener('resize', this.checkVisibleItems);
  }

  prev = () => {
    const { lastVisibleItemIndex, visibleCount } = this.state;
    const index = Math.max(lastVisibleItemIndex - (visibleCount - 1) * 2, 0);

    if (index >= 0) {
      this.scrollToItem(index);
    }
  };

  next = () => {
    const { itemsCount } = this.props;
    const { lastVisibleItemIndex } = this.state;
    const itemToScrollIndex = Math.min(lastVisibleItemIndex, itemsCount - 1);

    if (itemToScrollIndex < itemsCount) {
      this.scrollToItem(itemToScrollIndex);
    }
  };

  scrollToItem = (index) => {
    const { isRTL } = this.props;
    const container = this.containerRef.current;
    const elementToScroll = container.children[index];

    if (isRTL) {
      const offsetRight =
        container.clientWidth -
        elementToScroll.offsetLeft -
        elementToScroll.clientWidth;

      container.scrollLeft = offsetRight * -1;
    } else {
      container.scrollLeft = elementToScroll.offsetLeft;
    }
  };

  checkHorizontalVisibleItems = () => {
    const { itemsCount } = this.props;
    const slider = this.containerRef.current;
    const { clientWidth, scrollLeft } = slider;
    const itemWidth = slider.firstChild.clientWidth;
    const scrolledCount = Math.ceil(
      (clientWidth + Math.abs(scrollLeft)) / itemWidth,
    );
    const lastVisibleItemIndex = Math.min(scrolledCount - 1, itemsCount - 1);

    this.visibleItemsChanged(lastVisibleItemIndex);
  };

  checkVerticalVisibleItems = () => {
    const { itemsCount } = this.props;

    if (this.props.vertical) {
      const slider = this.containerRef.current;
      const itemHeight = slider.firstChild.clientHeight;
      const rect = slider.getBoundingClientRect();
      const height = window.innerHeight - rect.y;
      const visibleCount = Math.ceil(height / itemHeight);
      const lastVisibleItemIndex = Math.min(visibleCount - 1, itemsCount - 1);

      if (lastVisibleItemIndex >= 0) {
        this.visibleItemsChanged(lastVisibleItemIndex);
      }
    }
  };

  visibleItemsChanged = (lastVisibleItemIndex) => {
    const { lastItemIndex } = this.state;
    const state = {
      lastVisibleItemIndex,
    };

    if (lastVisibleItemIndex > lastItemIndex) {
      this.props.loadMore(lastVisibleItemIndex - lastItemIndex);
      state.lastItemIndex = lastVisibleItemIndex;
    }

    this.setState(state);
  };

  countVisibleItems = () => {
    const slider = this.containerRef.current;
    const { clientWidth, clientHeight } = slider.firstChild;
    let visibleCount;

    if (this.props.vertical) {
      visibleCount = Math.ceil(slider.clientHeight / clientHeight);
    } else {
      visibleCount = Math.ceil(slider.clientWidth / clientWidth);
    }

    this.setState({
      visibleCount,
    });
  };

  checkVisibleItems = _.debounce(() => {
    this.countVisibleItems();

    if (this.props.vertical) {
      this.checkVerticalVisibleItems();
    } else {
      this.checkHorizontalVisibleItems();
    }
  }, TIMEOUT);

  handleScroll = _.debounce(this.checkHorizontalVisibleItems, TIMEOUT);

  handleParentScroll = _.debounce(this.checkVerticalVisibleItems, TIMEOUT);

  renderArrows() {
    const { itemsCount, vertical, arrowClassName, isRTL } = this.props;
    const { lastVisibleItemIndex, visibleCount } = this.state;

    if (vertical || visibleCount > itemsCount) {
      return null;
    }

    const container = this.containerRef.current;
    const isPrevVisible = container && Math.abs(container.scrollLeft) > 0;
    let isNextVisible = itemsCount > visibleCount;

    if (lastVisibleItemIndex === itemsCount - 1) {
      const lastItem = container.children[lastVisibleItemIndex];
      const offset = isRTL
        ? container.clientWidth - lastItem.offsetLeft - lastItem.clientWidth
        : lastItem.offsetLeft;

      isNextVisible =
        Math.abs(container.scrollLeft) + container.clientWidth <
        offset + lastItem.clientWidth;
    }

    return (
      <>
        <div
          onClick={isRTL ? this.next : this.prev}
          className={classNames(
            styles.arrow,
            styles.arrowPrev,
            arrowClassName,
            {
              [styles.arrowVisible]: isRTL ? isNextVisible : isPrevVisible,
            },
          )}
        />
        <div
          onClick={isRTL ? this.prev : this.next}
          className={classNames(
            styles.arrow,
            styles.arrowNext,
            arrowClassName,
            {
              [styles.arrowVisible]: isRTL ? isPrevVisible : isNextVisible,
            },
          )}
        />
      </>
    );
  }

  render() {
    const { itemClassName, items, itemsCount, renderItem, vertical, isRTL } =
      this.props;

    const { visibleCount, lastItemIndex } = this.state;
    const index = lastItemIndex + visibleCount * 2;
    const arr = _.map(
      Array(itemsCount).slice(0, index),
      (value, index) => items[index],
    );

    return (
      <div
        className={classNames(styles.slider, {
          [styles.horizontal]: !vertical,
          [styles.vertical]: vertical,
        })}
        ref={this.containerRef}
        onScroll={this.handleScroll}
      >
        {_.map(arr, (item) => (
          <div
            className={classNames(styles.sliderItem, itemClassName, {
              [styles.isRTL]: isRTL,
            })}
            key={item}
          >
            {renderItem(item)}
          </div>
        ))}
        {this.renderArrows()}
      </div>
    );
  }
}

export default Slider;
