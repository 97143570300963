import React, { FC, useMemo } from 'react';
import { useStyles } from '@wix/tpa-settings/react';
import loadable from '@wix/yoshi-flow-editor/loadable';
import stylesParams from '../stylesParams';
import type { NewWidgetProps } from './new';
import { OldWidget, OldWidgetProps } from './old';
import { shouldUseNewWidget } from './new/utils/should-use-new-widget';
import { useEnvironment, useExperiments } from '@wix/yoshi-flow-editor';
import { useChannelId } from './new/hooks';

const NewWidget = loadable(() => import('./new'));

const Widget: FC<NewWidgetProps | OldWidgetProps> = (props) => {
  const styles = useStyles();
  const { isMobile } = useEnvironment();
  const { experiments } = useExperiments();
  const channelLayout = styles.get(stylesParams.channelLayout);
  const channelId = useChannelId();
  const isChannel = Boolean(channelId);

  const useNewWidget = useMemo(
    () => shouldUseNewWidget(channelLayout, isMobile, isChannel, experiments),
    [channelLayout, isMobile, isChannel, experiments],
  );

  if (useNewWidget) {
    return <NewWidget {...(props as NewWidgetProps)} />;
  }

  return <OldWidget {...(props as OldWidgetProps)} />;
};

export default Widget;
