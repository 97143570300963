import { useSiteInfo } from '../common/providers/SiteInfoProvider';
import { useChannelVideos } from './useChannelVideos';

export const useSharedVideo = () => {
  const { query, compId } = useSiteInfo();
  const { videos } = useChannelVideos();

  const compIdFromUrl = query['wix-vod-comp-id'];
  const videoIdFromUrl = query['wix-vod-video-id'];

  return compIdFromUrl && videoIdFromUrl && compIdFromUrl === compId
    ? videos.find((video) => video.id === videoIdFromUrl)
    : undefined;
};
