import { toError } from '../../parsers/transport-error';
import { keysToCamelCase, keysToSnakeCase } from '@wix/wix-vod-shared/common';
import { ItemType } from '@wix/wix-vod-constants/api-types';
import { Channel, ChannelType } from '../../../redux/types';

import { parseChannel } from './parsers';
import { getPublicApi } from '../index';
import { HttpResponse } from '@wix/http-client';

export const getVideoPreviewUrls = (channelId: string, instance: string) => {
  const { oldPublicChannelService } = getPublicApi();
  return oldPublicChannelService
    .getVideoPreviewUrls(channelId, instance)
    .then((response) => response.data);
};

export interface Purchase {
  currency: string;
  date: string;
  dateTs: number;
  metasiteId: string;
  price: number;
}

export interface Subscription {
  subscription: Purchase;
}

export interface Sale {
  sale: Purchase;
}

export interface Rent {
  rent: Purchase;
}

export interface MemberInfoItemBase {
  itemType?: ItemType.Item;
  // memberId: string;
  // usageInfo: null;
  listId?: string;
}

export interface MemberInfoList extends MemberInfoItemBase {
  itemId: null;
  // usageInfo: null;
  dgsInfo: Subscription;
}

export interface MemberInfoListItem extends MemberInfoItemBase {
  itemId: string;
  dgsInfo: Sale | Rent;
}

export type MemberInfoItem = MemberInfoList | MemberInfoListItem;

export type MemberInfoData = {
  data: {
    items: MemberInfoItem[];
  };
};

export const getMemberInfo = async (
  baseUrl: string,
  channelId: string,
  instance: string,
  hydratedMemberInfo?: MemberInfoData,
): Promise<MemberInfoData> => {
  const { oldPublicChannelService } = getPublicApi();

  try {
    return keysToCamelCase(
      hydratedMemberInfo ??
        (await oldPublicChannelService.getMemberInfo(channelId, instance)),
    );
  } catch (error) {
    return toError((error as { response: HttpResponse }).response);
  }
};

export const getChannel = async (
  channelId: string,
  hydratedChannel: any,
  templateMetaSiteId?: string,
): Promise<{ data: Channel }> => {
  const { oldPublicChannelService } = getPublicApi();

  const response =
    hydratedChannel ||
    (await oldPublicChannelService.getInfo(channelId, undefined, {
      params: { metaSiteId: templateMetaSiteId },
    }));

  try {
    const channel = keysToSnakeCase(response.data);
    channel.youtube_data = keysToCamelCase(channel.youtube_data);

    const { custom_cover_url, cover_url, featured_item, channel_type } =
      channel;

    if (
      !custom_cover_url &&
      !cover_url &&
      featured_item &&
      channel_type !== ChannelType.YOUTUBE_FEED
    ) {
      channel.cover_url = extractCoverFromVideoItem(channel.featured_item);
    }

    return { data: parseChannel(channel) };
  } catch (error) {
    return toError((error as { response: HttpResponse }).response!);
  }
};

const extractCoverFromVideoItem = (videoItem: any) =>
  videoItem.custom_cover_url || videoItem.cover_url;
