import { useContext, createContext } from 'react';
import { ModulesProps } from '../worker/modules';

export const WorkerContext = createContext<ModulesProps | null>(null);

export function useWorker() {
  const context = useContext(WorkerContext);

  if (!context) {
    throw new Error('Use this hook within WorkerContext');
  }

  return context;
}
