import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isButtonsTextShown } from '../../../../../selectors/app-settings';
import { playButtonText, playButtonAction } from '../../ui-selectors/buttons';
import { withTranslation } from '@wix/yoshi-flow-editor';

const mapStateToProps = (state, props) => ({
  text: playButtonText(state, props),
  action: playButtonAction(state, props),
  isTextShown: isButtonsTextShown(state),
});

export default withTranslation()(
  connect(mapStateToProps)(
    class PlayButton extends React.Component {
      static propTypes = {
        videoItem: PropTypes.object.isRequired,
        text: PropTypes.string,

        icon: PropTypes.node,

        action: PropTypes.func,
        onMemberSignUp: PropTypes.func.isRequired,
        onPlayRequestedAllowed: PropTypes.func.isRequired,
        onPlayRequestedDisallowed: PropTypes.func.isRequired,

        isChannelCoverView: PropTypes.bool,
        isTextShown: PropTypes.bool,

        className: PropTypes.string,
      };

      render() {
        const { text, action, className, icon, isTextShown } = this.props;

        if (!text) {
          return null;
        }

        return (
          <div
            data-hook="overlay-play-button"
            role="button"
            aria-label={this.props.t(text)}
            onClick={action}
            className={className}
          >
            {icon}
            <span>{isTextShown && this.props.t(text)}</span>
          </div>
        );
      }
    },
  ),
);
