import React, { useContext } from 'react';
import { GqlChannelFragment } from '../apollo/generated/graphql';

export type ChannelContextType = GqlChannelFragment | undefined;

export const ChannelContext =
  React.createContext<ChannelContextType>(undefined);

export function useChannelContext() {
  return useContext(ChannelContext);
}

export function useChannel() {
  const channel = useChannelContext();

  if (!channel) {
    throw new Error(
      '"This channel is coming soon" should be rendered if the channel is not available',
    );
  }

  return channel;
}
