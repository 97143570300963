import { useTranslation } from '@wix/yoshi-flow-editor';
import {
  GqlVideoFragment,
  GqlVideoTrailerType,
} from '../apollo/generated/graphql';
import { useVideoAccessAction } from './useVideoAccessAction';
import { AccessAction } from '../types/enums';

type UseVideoPlayButton =
  | {
      canPlay: false;
      playText: undefined;
    }
  | {
      canPlay: true;
      playText: string;
    };

export function useVideoPlayButton(
  video: GqlVideoFragment,
): UseVideoPlayButton {
  const { t } = useTranslation();
  const videoAccessAction = useVideoAccessAction(video);
  const { trailerEnabled, trailerType } = video.viewerOptions;

  if (video.play.entity?.url) {
    if (videoAccessAction === AccessAction.None && video.play.entity.url) {
      return {
        canPlay: true,
        playText: t('widget.overlay-buttons.play-video'),
      };
    }

    if (trailerEnabled) {
      return {
        canPlay: true,
        playText:
          trailerType === GqlVideoTrailerType.Default
            ? t('widget.overlay-buttons.watch-preview')
            : t('widget.overlay-buttons.watch-trailer'),
      };
    }
  }

  return {
    canPlay: false,
    playText: undefined,
  };
}
